/* font families */
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,700');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

/**
 * brand color palette
 * orange: #EE7A30
 * pink: #EA368A
 * purple: #7A2CF5
 * grey 5%: #F6F7F8
 * grey 10%: #EEF0F1
 * grey 25%: #D6D9DD
 * grey 50%: #ADB4B9
 * grey 75%: #838F98
 * grey (100%): #5A6A76
 * dark grey: #30393D
 * dark grey black: #101213
 *
 * additional colors
 * red: #D87B7B
 * high contrast dark grey: #484E59
 */

/* base styles */
.App {
  min-height: 100vh;
}

/* base typography */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  margin: 0;
}
h1 {
  color: #5A6A76;
}
h2 {
  font-size: 30px;
}
h3 {
  font-size: 22px;
}
h4, h5, h6 {
  font-size: 18px;
}
p {
  margin: 0;
}
input,
textarea,
select,
option {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  color: #101213;
}
.subtitle {
  font-weight: 700;
}

/* links */
a {
  color: #7A2CF5;
}

a:hover,
a:active,
a:focus {
  color: #7A2CF5;
}

/* layout */
main {
  min-height: 80vh;
}
/* forms */
.AppFormTitle {
  font-size: 25px;
  margin-top: 0;
  margin-bottom: 25px;
}
.AppInput {
  margin-bottom: 20px;
}
.StripeInput {
  border: 1px solid #EEF0F1;
  margin-bottom: 15px;
  padding: 16px;
}
.ConfirmationButton {
  width: 100%;
}
/* form error message */
.errorMessage {
  color: red;
  font-weight: bolder;
  margin: 0 15% -3% 15%;
  height: 20px;
  text-align: center;
}
.AppInput.error {
  color: white;
  background: #EA368A;
  background: #EE7A30;
  background: #EE7A30;
  padding: 10px;
}
.AppInput.error p {
  margin: 0;
}
/* form input error message */
.AppInput.has-error input[type="text"],
.AppInput .has-error input[type="text"],
.AppInput.has-error input[type="date"],
.AppInput .has-error input[type="date"],
.AppInput.has-error input[type="number"],
.AppInput .has-error input[type="number"],
.AppInput.has-error select,
.AppInput .has-error select {
  border-color: #EE7A30;
}
.AppInputError {
  color: #EE7A30;
  font-size: 11px;
}
.AppInput label {
  display: inline-block;
  margin-bottom: 10px;
}
.AppInput input[type="text"],
.AppInput input[type="email"],
.AppInput input[type="date"],
.AppInput input[type="password"],
.AppInput input[type="number"],
.AppInput select,
.AppInput textarea {
  display: block;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  border: 1px solid #EEF0F1;
  margin: 0 0 18px;
  padding: 15px;
}
.AppInput textarea {
  border: 1px solid #EEF0F1;
}
label.AppInput-radio-label {
  display: block;
  position: relative;
  user-select: none;
  cursor: pointer;
  padding: 5px 10px 5px 35px;
  margin: 0;
}
.AppInput-radio-checkmark {
  content: " ";
  position: absolute;
  top: 5px;
  left: 0px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #EEF0F1;
  transition: background-color 300ms;
}
.AppInput-radio-label:hover .AppInput-radio-checkmark {
  background-color: #ADB4B9;
}
.AppInput-radio-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.AppInput-radio-label input:checked ~ .AppInput-radio-checkmark {
  background-color: #EE7A30;
  background-color: #30393D;
  background-color: #484E59;
  /*
  background-image: linear-gradient(to right, #EE7A30, #EA368A, #7A2CF5);
  */
}
.AppInput-radio-label input:checked ~ .AppInput-radio-checkmark:after {
  content: " ";
  display: block;
  position: absolute;
  left: 8.5px;
  top: 8.5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: white;
}
.AppSubmit-2-col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20%;
  margin: 0;
}
.AppSubmit button {
  width: 100%;
  display: inline-block;
  padding: 15px;
}

