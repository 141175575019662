.Grid5Columns {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    text-align: center;
}

/* .transaction-column-styling {
    color: #838F98;
    color: #30393D;
    text-transform: uppercase;
    -webkit-letter-spacing: 2px;
    -moz-letter-spacing: 2px;
    -ms-letter-spacing: 2px;
    letter-spacing: 2px;
    padding: 15px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right,#EE7A30,#EA368A,#7A2CF5);
    border-image-slice: 1;
    background-color: #fff;
} */
