.ItemList {
  margin: 15px 20px;
}
.ItemList.is-open {
  background-color: #F6F7F8;
}
.ItemList-header {
  cursor: pointer;
  display: grid;
  padding: 15px 20px;
  grid-template-columns: 8fr 1fr;
  grid-column-gap: 5%;
  transition: background-color 475ms ease;
}
.ItemList.is-open .ItemList-header {
  background-color: #EEF0F1;
}
.ItemList-header:hover {
  background-color: #EEF0F1;
}
.ItemList.is-open .ItemList-header:hover {
  background-color: #D6D9DD;
}
.ItemList-title {
  font-size: 17px;
}
.ItemList-title.is-type-all-items {
  font-size: 25px;
}
.ItemList-header-open-indicator {
  margin-right: 20px;
}
.ItemList-total {
  font-size: 15px;
  text-align: right;
}
.ItemList-total.is-type-all-items {
  font-size: 20px;
}
.ItemList-description {
  padding: 10px 20px;
}
.ItemList-bulk-actions {
  padding: 10px 20px;
}
.ItemList-bulk-actions .AppInput {
  margin-bottom: 0;
}
.ItemList-bulk-actions .AppInput select {
  margin-bottom: 0;
}
.ItemList-items-header,
.ItemList-item {
  display: grid;
  padding: 10px 20px;
  grid-template-columns: 0.1fr 6fr 2fr 1fr 2fr;
  grid-column-gap: 5%;
  transition: background-color 475ms ease;
}
.ItemList-items-header {
  font-weight: 700;
}
.ItemList-item.is-editable:hover {
  cursor: pointer;
}
.ItemList-item:hover {
  background-color: #D6D9DD;
}
.ItemList-item.is-selected {
  background-color: #EEF0F1;
  background-color: #EBCEFF;
}
.ItemList-item.is-selected:hover {
  background-color: #EEF0F1;
  background-color: #EBCEFF;
  background-color: #D9AFF6;
}
.ItemList-items-header {
  grid-template-areas: "select title date amount actions";
  border-bottom: 1px solid #EEF0F1;
  border-bottom: 0px solid #EEF0F1;
}
.ItemList-main {
  padding-bottom: 20px;
}
.ItemList-items-header-date {
  grid-area: date;
}
.ItemList-items-header-amount {
  text-align: right;
  grid-area: amount;
}
.ItemList-items-header-actions {
  grid-area: actions;
}
.App a.ItemList-item-select {
  color: #484E59;
}
.App a.ItemList-item-select:hover {
  color: #000;
}
.App a.ItemList-item-action {
  color: #5A6A76;
  color: #ADB4B9;
  color: #484E59;
  margin: 0 10px 0;
  /*opacity: 0.25;
  transition: opacity 475ms ease, color 475ms ease;
  */
}
.App a.ItemList-item-action:hover,
.App a.ItemList-item-action:active {
  color: #EE7A30;
  color: #484E59;
}
.App a.ItemList-item-comments-link {
  color: #5A6A76;
  color: #ADB4B9;
  color: #484E59;
  margin: 0 0 0 20px;
  opacity: 0.25;
  transition: opacity 475ms ease, color 475ms ease;
}
a.ItemList-item-comments-link:hover,
a.ItemList-item-comments-link:active {
  color: #EE7A30;
  color: #484E59;
}
.ItemList-item:hover a.ItemList-item-action,
.ItemList-item:hover a.ItemList-item-comments-link {
  opacity: 1;
}
.ItemList-item-amount {
  text-align: right;
}
.ItemList-item.is-negative .ItemList-item-amount {
  color: #D87B7B;
}
