.ArticlePreview {
  margin: 35px 20px;
  padding: 20px;
  background-color: white;
}
.ArticlePreview-image img {
  max-width: 100%;
}
.ArticlePreview .ArticlePreview-category {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 12px;
}
.ArticlePreview-title {
  margin-top: 15px;
  margin-bottom: 20px;
}
.ArticlePreview-title a,
.ArticlePreview-title a:active,
.ArticlePreview-title a:focus,
.ArticlePreview-title a:hover {
  text-decoration: none;
}
.ArticlePreview-title a:hover {
  text-decoration: underline;
}
.ArticlePreview p {
  margin-bottom: 20px;
}

