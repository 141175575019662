.Footer {
  color: white;
  margin-top: 0px;
  background-color: #30393D;
  padding-bottom: 50px;
}
.Footer-disclaimer {
  font-size: 10px;
}
.Footer p:last-child {
  margin-bottom: 0;
}
.Footer-nav {
  font-size: 12px;
  list-style-type: none;
}
.Footer-nav-item {
  display: inline-block;
  padding-left: 0;
  margin-right: 20px;
}
.App .Footer-nav-item a,
.App .Footer-nav-item a:active,
.App .Footer-nav-item a:focus,
.App .Footer-nav-item a:hover {
  color: white;
  text-decoration: none;
}
.App .Footer-nav-item a:hover {
  text-decoration: underline;
}
