.GridCenter {
  min-width: 100%;
  min-height: 100%;
  display: grid;
  grid-template-areas:
    ". . ."
    ". a ."
    ". . .";
}
.GridCenter.ver-full {
  min-height: 100vh;
}
.GridCenter-item {
  position: relative;
  grid-area: a;
  align-self: center;
  justify-self: center;
}
